import { Box, Container } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { publicIpv4 } from "public-ip";
import React, { useEffect, useState } from "react";
import { browserName, browserVersion, isMobile, isTablet, osName } from "react-device-detect";
import { Country as CountryCode } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AbrezapButton } from "./components/AbrezapButton";
import { Descricao } from "./components/Descricao";
import { Footer } from "./components/Footer";
import { GerarQrcodeButton } from "./components/GerarQrcodeButton";
import { Header } from "./components/Header";
import { PhoneInternacional } from "./components/PhoneInternacional";
import { QrcodeModal } from "./components/QrcodeModal";
import { RatingCommentModal } from "./components/RatingCommentModal";
import { ShareButtons } from "./components/ShareButtons";
import { SnowFlakes } from "./components/SnowFlakes";
import { setupAxiosInterceptors } from "./errorInterceptor";

setupAxiosInterceptors();

interface ApiResponse {
  success: boolean;
  link: string;
  qrCodeBase64?: string;
  paisISOCode?: string;
}

interface RpsResponse {
  success: boolean;
  mensagem: string;
}

interface RegistoLocalStorage {
  data: string;
  hora: string;
  telefone: string;
  qrcode: string;
}

const App: React.FC = () => {
  const [phone, setPhone] = useState<string | undefined>();
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [userIP, setUserIP] = useState<string>("");
  const [userCountry, setUserCountry] = useState<CountryCode>();

  const getPlatform = () => {
    if (isMobile) return "Mobile";
    if (isTablet) return "Tablet";
    return "Desktop";
  };

  const userAgent = navigator.userAgent;
  const platform = getPlatform();

  const registrarNoLocalStorage = (telefone: string, qrcode: string | null) => {
    const registros: RegistoLocalStorage[] = JSON.parse(localStorage.getItem("historico") ?? "[]");
    const agora = new Date();
    const novoRegistro: RegistoLocalStorage = {
      data: agora.toLocaleDateString(),
      hora: agora.toLocaleTimeString(),
      telefone,
      qrcode: qrcode ?? "",
    };
    registros.push(novoRegistro);
    localStorage.setItem("historico", JSON.stringify(registros));
  };

  const atualizarLocalStorage = (telefone: string, qrcode: string) => {
    const registros: RegistoLocalStorage[] = JSON.parse(localStorage.getItem("historico") ?? "[]");
    const registroIndex = registros.findIndex((registro) => registro.telefone === telefone);

    if (registroIndex !== -1) {
      registros[registroIndex].qrcode = qrcode;
    } else {
      const agora = new Date();
      registros.push({
        data: agora.toLocaleDateString(),
        hora: agora.toLocaleTimeString(),
        telefone,
        qrcode,
      });
    }

    localStorage.setItem("historico", JSON.stringify(registros));
  };

  const telefoneJaRegistrado = (telefone: string): boolean => {
    const registros: RegistoLocalStorage[] = JSON.parse(localStorage.getItem("historico") ?? "[]");
    return registros.some((registro) => registro.telefone === telefone);
  };

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const ip = await publicIpv4();
        setUserIP(ip);
      } catch (error) {
        console.error("Erro ao obter o IP do usuário:");
      }
    };
    fetchIP();
  }, []);

  useEffect(() => {
    if (userIP) {
      const fetchCountry = async () => {
        try {
          const response: AxiosResponse<ApiResponse> = await axios.get("api/geoip", { params: { ip: userIP } });
          if (response.data.success) {
            setUserCountry((response.data.paisISOCode as CountryCode) || "BR");
          }
        } catch (error) {
          console.error("Erro ao obter o País do usuário:");
          setUserCountry("BR");
        }
      };
      fetchCountry();
    }
  }, [userIP]);

  const handleAbreZap = async () => {
    const telefoneFormatado = phone?.replace(/\D/g, "") ?? "";
    if (!telefoneJaRegistrado(telefoneFormatado)) {
      try {
        const response: AxiosResponse<ApiResponse> = await axios.post("api/send", {
          phone: telefoneFormatado,
          moreInfo: {
            ip: userIP,
            platform,
            os: osName,
            browser: browserName,
            navVer: browserVersion,
            isMobile,
            isTablet,
            agent: userAgent,
          },
        });
        if (response.data.success) {
          registrarNoLocalStorage(telefoneFormatado, null);
          window.open(response.data.link, "_self");
        }
      } catch {
        console.error("Erro desconhecido ao enviar requisição");
      }
    } else {
      window.open(`whatsapp://send?phone=${telefoneFormatado}&type=phone_number&app_absent=0`, "_self");
    }
  };

  const handleRps = async (rating: number, comment: string) => {
    try {
      const response: AxiosResponse<RpsResponse> = await axios.post("api/pesquisa", {
        nota: rating,
        mensagem: comment,
        moreInfo: {
          ip: userIP,
          platform,
          os: osName,
          browser: browserName,
          navVer: browserVersion,
          isMobile,
          isTablet,
          agent: userAgent,
        },
      });
      console.log(rating, comment);
      if (response.data.success) {
        toast.success(response.data.mensagem);
      }
    } catch {
      console.error("Erro desconhecido ao enviar requisição");
    }
  };

  const handleGerarQRCode = async () => {
    const telefoneFormatado = phone?.replace(/\D/g, "") ?? "";
    const registros: RegistoLocalStorage[] = JSON.parse(localStorage.getItem("historico") ?? "[]");
    const registroExistente = registros.find((registro) => registro.telefone === telefoneFormatado);

    if (registroExistente?.qrcode) {
      setQrCode(registroExistente.qrcode);
      setIsModalOpen(true);
    } else {
      try {
        const response: AxiosResponse<ApiResponse> = await axios.post("api/qrcode", {
          phone: telefoneFormatado,
          moreInfo: {
            ip: userIP,
            platform,
            os: osName,
            browser: browserName,
            navVer: browserVersion,
            isMobile,
            isTablet,
            agent: userAgent,
          },
        });
        if (response.data.success && response.data.qrCodeBase64) {
          setQrCode(response.data.qrCodeBase64);
          setIsModalOpen(true);
          atualizarLocalStorage(telefoneFormatado, response.data.qrCodeBase64);
        }
      } catch {
        console.error("Erro desconhecido ao gerar QRCode");
      }
    }
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <SnowFlakes />
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          textAlign: "center",
          paddingBottom: "128px",
        }}
      >
        <Box flex="1">
          <ToastContainer position="top-center" draggable />
          <Header />
          <PhoneInternacional phone={phone} setPhone={setPhone} userCountry={userCountry} />
          <Box display="flex" justifyContent="center" gap={2}>
            <AbrezapButton phone={phone} handleAbreZap={handleAbreZap} />
            <GerarQrcodeButton phone={phone} handleGerarQRCode={handleGerarQRCode} />
          </Box>
          <Descricao />
          <RatingCommentModal onSubmit={handleRps} />
        </Box>
      </Container>

      <ShareButtons />

      <Footer />

      <QrcodeModal isModalOpen={isModalOpen} closeModal={closeModal} qrCode={qrCode} phone={phone} />
    </>
  );
};

export default App;
